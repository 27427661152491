import styles from './ProductPrice.module.scss';
import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'components/primitives/grid';
import { Placeholder } from 'components/primitives/placeholders';
import { renderHTML } from 'utils/render';
import { useSanaTexts } from 'components/sanaText';
import { getFormatPrice } from 'utils/format';
import { useCurrencyInfo } from 'utils/hooks';

const ListPriceBlock = ({ salesPrice, listPrice }) => {
  const currencyInfo = useCurrencyInfo();
  const formatAsPrice = currencyInfo && getFormatPrice(currencyInfo);

  const formattedPrice = useMemo(() => {
    if (!currencyInfo)
      return null;

    return renderHTML(formatPrice(formatAsPrice(listPrice), currencyInfo));
  }, [currencyInfo, listPrice]);


  const { texts: [
    priceLbl,
  ], loaded } = useSanaTexts([
    'ListPrice',
  ]);

  if (!loaded || formattedPrice == null)
    return <Placeholder className="price-block product-price-placeholder" />;

  return (
    <>
      <Col xs="auto" className="price-block" title={priceLbl}>
        <i className="visually-hidden">{priceLbl} {currencyInfo.symbol + listPrice}</i>
        <span className={`${styles.actualPrice} actual-price`} aria-hidden suppressHydrationWarning>
          {formattedPrice}
        </span>
      </Col>
    </>
  );
};

ListPriceBlock.propTypes = {
  salesPrice: PropTypes.number,
  listPrice: PropTypes.number,
};

export default memo(props => {
  if (props.salesPrice === null)
    return null;

  if (typeof props.salesPrice === 'undefined')
    return <Placeholder className="price-block product-price-placeholder" />;

  return <ListPriceBlock {...props} />;
});

function formatPrice(price, currencyInfo) {
  if (currencyInfo.decimalDigits === 0)
    return price;

  return price.replace(/(\d+)([^\d]*)$/, '<sup>$1</sup>$2');
}
